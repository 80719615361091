<template>
  <div class="multi-tools">
    <div>
      <div class="image-button-container">
        <div
          v-if="showApp != 'file-upload-bad-guys'"
          class="image-button"
          @click="goToFileUploadBadGuys"
          :style="{ backgroundImage: 'url(' + catchBadGuys + ')' }"
        >
          <span class="button-text">Catch 'em all!<br />🦸🏻 ⛓️ 🦹🏻</span>
        </div>
        <div class="sub-text" @click="goToFileUploadBadGuys">
          Détection d'IP malveillantes <br />
          depuis un access.log Traefik
        </div>
        <FileUploaderGen
          v-if="store.isLoggedIn && showApp === 'file-upload-bad-guys'"
          :route="'/bad_guys_list'"
          :fileName="'bad_guys_list.txt'"
        />
      </div>
    </div>
    <div>
      <div class="image-button-container">
        <div
          v-if="showApp != 'file-upload-pdf2word'"
          class="image-button"
          @click="goToFileUploaderPdf2Word"
          :style="{ backgroundImage: 'url(' + pdfToWord + ')' }"
        >
          <span class="button-text">PDF ⏩ Word </span>
        </div>
        <div class="sub-text" @click="goToFileUploaderPdf2Word">
          Conversion PDF ⏩ Word (.docx)
        </div>
        <FileUploaderGen
          v-if="store.isLoggedIn && showApp === 'file-upload-pdf2word'"
          :route="'/pdf_to_word'"
          :fileName="'pdf_to_word.docx'"
        />
      </div>
    </div>
  </div>
</template>



<script>
import FileUploaderGen from "./FileUploaderGen.vue";
import { store } from "../store";

export default {
  setup() {
    return { store };
  },
  components: {
    FileUploaderGen,
  },
  data() {
    return {
      catchBadGuys: require("../../images/bad_guys.webp"),
      pdfToWord: require("../../images/pdf_to_word.webp"),
      showApp: "",
    };
  },
  props: {
    userRole: String,
  },
  methods: {
    goToFileUploadBadGuys() {
      this.showApp = "file-upload-bad-guys"; // Met à jour l'état pour afficher le composant
    },
    goToFileUploaderPdf2Word() {
      this.showApp = "file-upload-pdf2word";
    },
  },
  mounted() {
    console.log("UserRole:", this.userRole);
    store.pucksMessage = "La, c'est pas tres range...";
  }
};
</script>


<style scoped>
</style>
