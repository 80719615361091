// store.js
import { reactive } from 'vue';

export const store = reactive({
  isLoggedIn: false,
  errorMessage: {
    visible: false,
    message: ''
  },
  pucksMessage: "Bonjour",
  userInfo:
  {
    token: '',
    userName: '',
    userRole: ''
  },
  fieldMap: {
    "MEP Name": "mep_name",
    "MEP nationalPoliticalGroup": "national_political_group",
    "MEP politicalGroup": "political_group",
    "Title": "title",
    "Place": "place",
    "Date": "date",
    "Meeting With": "meeting_with",
    "Meeting Related to Procedure": "meeting_related_to_procedure",
  },
  showApp: '',
  wait(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },
  async generatePucksLoading() {
    this.pucksMessage = "Du travail, encore du travail";
    await store.wait(1000);
    this.pucksMessage = store.pucksMessage + ".";
    await store.wait(1000);
    this.pucksMessage = store.pucksMessage + ".";
    await store.wait(1000);
    this.pucksMessage = store.pucksMessage + ".";
    await store.wait(1000);
    this.pucksMessage = store.pucksMessage + ".";
    await store.wait(1000);
    this.pucksMessage = store.pucksMessage + ".";
    
  }
});



